import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

const ContactPage = () => {
  return (
    <>
      <Helmet />
      <Layout />
    </>
  )
}

export default ContactPage
